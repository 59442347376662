import { ConfigProvider } from "antd";
import en from "antd/locale/en_GB";
import App from "App";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import ReactDOM from "react-dom/client";
import { AntCustomTheme } from "setupAnt";
import "styles.css";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Berlin");
dayjs.locale("en-gb");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <ConfigProvider theme={AntCustomTheme} locale={en}>
    <App />
  </ConfigProvider>
);
