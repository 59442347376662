import { MenuOutlined } from "@ant-design/icons";
import { Menu, theme } from "antd";
import { useLocation } from "react-router-dom";

export const items = [
  {
    key: "courses",
    label: (
      <>
        <img className="brain-logo" src="/logo.webp" alt="brain logo" />
        COURSES
      </>
    ),
  },
  {
    key: "course-overviews",
    label: "COURSE-OVERVIEW",
  },
  {
    key: "slot-overview",
    label: "SLOT-OVERVIEW",
  },
  {
    key: "attendances",
    label: "ATTENDANCES",
  },
  {
    key: "trainers",
    label: "TRAINERS",
  },
  {
    key: "brainers",
    label: "BRAINERS",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  const location = useLocation();

  const { token } = theme.useToken();

  return (
    <Menu
      mode="horizontal"
      items={items}
      defaultSelectedKeys={["courses"]}
      selectedKeys={[location.pathname.replace(/\//g, "")]}
      overflowedIndicator={<MenuOutlined />}
      onSelect={({ key }) => {
        window.location.pathname = key;
      }}
      style={{ backgroundColor: token.colorPrimaryBg, border: 0, marginLeft: "120px" }}
    />
  );
}
