import { Layout, theme } from "antd";
import Navigation from "components/Navigation";
import { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (): ReactElement {
  const { token } = theme.useToken();

  return (
    <BrowserRouter>
      <Layout style={{ backgroundColor: "unset" }}>
        <Layout.Header
          style={{
            borderBottom: "1px solid rgba(5, 5, 5, 0.06)",
            backgroundColor: token.colorPrimaryBg,
          }}
        >
          <Navigation />
        </Layout.Header>
        <Layout.Content>
          <Routes />
        </Layout.Content>
      </Layout>
    </BrowserRouter>
  );
}
