import { Result } from "antd";
import LoadingSpinner from "components/LoadingSpinner";
import { lazy, ReactElement, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

const Attendances = lazy(() => import("pages/Attendances"));
const CourseDetails = lazy(() => import("pages/CourseDetails"));
const Courses = lazy(() => import("pages/Courses"));
const CourseOverviews = lazy(() => import("pages/CourseOverviews"));
const Login = lazy(() => import("pages/Login"));
const SlotOverviews = lazy(() => import("pages/SlotOverviews"));
const Trainers = lazy(() => import("pages/Trainers"));
const Users = lazy(() => import("pages/Users"));

function suspenseElement(children: ReactElement) {
  return <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/courses" />} />
      <Route path="/courses" element={suspenseElement(<Courses />)} />
      <Route path="/courses/:courseId" element={suspenseElement(<CourseDetails />)} />
      <Route path="/course-overviews" element={suspenseElement(<CourseOverviews />)} />
      <Route path="/slot-overview" element={suspenseElement(<SlotOverviews />)} />
      <Route path="/attendances" element={suspenseElement(<Attendances />)} />
      <Route path="/trainers" element={suspenseElement(<Trainers />)} />
      <Route path="/brainers" element={suspenseElement(<Users />)} />
      <Route path="/login" element={suspenseElement(<Login />)} />
      <Route
        path="*"
        element={<Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />}
      />
    </Routes>
  );
}
