import { Row, Spin } from "antd";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <Row justify="center" align="middle" style={{ padding: "16px 0" }}>
      <Spin tip="Loading" />
    </Row>
  );
}
